export const environment = {
  production: true,
  SOCKET_URL: "https://chat.airteltigo.com.gh/",
  SONG_PLAY_URL: "http://10.176.122.207:8091/webcont",
  AUTH_URL: "https://auth.airteltigo.com.gh/",
  MFS_RBT_URL: "https://mfs.airteltigo.com.gh/",
  USER_URL: "https://user.airteltigo.com.gh/",
  s3Bucket: "assets/Loader/",
  headerEnrichmentUrl: "https://user.airteltigo.com.gh",
  uniqueKey: "eGFudGhpdW1BcHByb2FjaGluZ1RyYW5xdWlsaXR5QmFzZQ==\n",
  firstEncodeString: "qwiojkl/nm",
  secondEncodeString: "nmbvxc",
  aesEncryptionKey: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
  aesEncryptionIV: [
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
  ],
  chatRoom: "abc123",
};
